@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import "custom";

body {
  background: #DFE5DC;
  font-family: 'Ubuntu', sans-serif;
}

.header {
  text-align: center;
}

span {
  &.dangerText {
    color: #dc3545;
  }

  &.infoText {
    color: #17a2b8;
    font-weight: bold;
  }
}

.stickla-logo {
  height: 100vh;
  text-align: center;
  img {
    margin-top: calc(50vh - 100px);
    max-width: 80vw;
  }
  p {
    margin-top: 10px;
  }
}